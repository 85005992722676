.wrapper {
  width: 30px;
  height: 30px;
  background-image: linear-gradient(to right, #8ec03e 0%, #a5ce39 100%);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  letter-spacing: 0.17px;
  text-transform: uppercase;
}
