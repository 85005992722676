.wrapper {
  position: relative;
  cursor: pointer;
}

.dropdown {
  position: relative;
  padding-left: 21px;
}

.arrow {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 9px;
}

.title {
  display: inline-block;
}

.popup {
  width: 340px;
  height: 60vh;
  position: absolute;
  left: 0;
  overflow-y: scroll;
  z-index: 10;
  @media (max-width: 375px) {
    width: 290px;
  }
}
