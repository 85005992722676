@import 'styles/_variables.scss';

.description {
  color: $main-font-color;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.paymentMethod {
  padding-top: 20px
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding: 30px 10px 15px 0px;
}

.cancelButton {
  max-width: 105px;
  min-width: 90px;
  width: 100%;
  margin-right: 15px;
}

.payNowButton {
  max-width: 145px;
  min-width: 90px;
  width: 100%;
}