@import 'styles/_variables.scss';

.wrapper {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 10px 0px;
  background-color: white;
  position: relative;
  box-shadow: 0 4px 16px rgba(47, 83, 151, 0.1), 0 0 2px rgba(47, 83, 151, 0.1);
  line-height: 36px;
  padding-left: calc(100vw - 100%);
  position: fixed;
  z-index: 10;
}

.innerContent {
  max-width: $content-max-width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
}

.leftBlock {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
}

.logoImg {
  display: block;
  margin-right: 24px;
  background-image: url('./img/logo@2x.png');
  width: 29px;
  height: 28px;
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 350px) {
    margin-right: 0;
  }
}

.wrapperSL {
  position: fixed;
  top: 58px;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 0;
  line-height: 36px;
  padding-left: calc(100vw - 100%);
  background-color: $background-color;
  z-index: 9;
}

.innerContentSL {
  max-width: $content-max-width;
  margin: 0 auto;
  padding: 0px 15px;
}
