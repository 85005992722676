.contentWrapper {
  padding-bottom: 10px;
}

.socialNumber {
  font-size: 16px;
  padding: 5px 0;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 26px;
}

.mphone {
  font-size: 13px;
  letter-spacing: 0.3px;
}

.mphone {
  font-weight: 600;
}

.locationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.linksWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: stretch;
  padding: 12px 0;
}

.blockWithImg {
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
  font-size: 13px;
  font-weight: 400;
  color: #0c2152;
  letter-spacing: 0.1px;
  line-height: 20px;
  &::before {
    text-align: center;
    margin-right: 18px;
    width: 20px;
    height: 20px;
  }
  &.location::before {
    content: url('./img/location.svg');
  }
  &.contact::before {
    content: url('./img/persone.svg');
  }
  &.provider::before {
    content: url('./img/service.svg');
  }
  &.hha::before {
    content: url('./img/agency.svg');
  }
}
