.wrapper {
  width: 100%;
}

.content {
  margin-top: 10px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 16px 0 24px 0;
}
