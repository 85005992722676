$breakpoint: 600px;

.wrapper {
  cursor: pointer;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(47, 83, 151, 0.1), 0 0 2px rgba(47, 83, 151, 0.1);
  border-radius: 3px;
  background-color: #ffffff;
  position: relative;
  border-left: 3px solid #f78e1e;
  &.viewed {
    border-left: 3px solid #1f8efa;
  }
  &:hover {
    box-shadow: 0 8px 16px rgba(47, 83, 151, 0.2),
      0 0 2px rgba(47, 83, 151, 0.1);
  }
}

.title {
  color: #05102a;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}

.body {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clinicianWrapper,
.date,
.duration,
.btn {
  margin-top: 6px;
}

.date {
  display: flex;
}

.endTime {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 12px;
  margin-left: 5px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 5px;
    height: 1px;
    background-color: #6d7a97;
  }
}

.clinicianWrapper {
  display: flex;
  align-items: center;
  color: #6d7a97;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  @media (min-width: $breakpoint) {
    flex-basis: 25%;
  }
}

.clinician {
  width: 100px;
  @media (max-width: 420px) {
    width: 70px;
  }
  &.cutInfo {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.clinicianIcon {
  margin-right: 8px;
}

.date,
.duration {
  color: #6d7a97;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  position: relative;
  padding-left: 10px;
  flex-shrink: 0;
}

.btn {
  width: 100px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid rgba(144, 193, 62, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: #8ec03e;
}
