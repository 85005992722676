.wrapper {
  margin-top: 30px;
}

.title {
  color: #6d7a97;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
}

.text {
  color: #0c2152;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  min-height: 21px;
}
