.message {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.23px;
}

.info {
  color: #0c2152;
}
.warning {
  color: #f44236;
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.button {
  max-width: 138px;
  width: 100%;
}

.cancelButton {
  margin-left: 20px;
}
