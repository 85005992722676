.note {
  color: #0c2152;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.23px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 36px;
}

.button {
  max-width: 238px;
  width: 100%;
}

.cancelButton {
  background-image: linear-gradient(to bottom, #f44236, #f4574a);
  margin-left: 20px;
}
