@import 'styles/_variables.scss';

$max-width: $content-max-width;

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 60px;
  flex-grow: 1;
  background-color: $background-color;
  padding-left: calc(100vw - 100%);
}

.innerContent {
  max-width: $max-width;
  margin: 0 auto;
  display: flex;
  padding: 10px 15px;
  flex-wrap: wrap;
  @media (max-width: 350px) {
    padding: 10px;
  }
}
