.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  box-sizing: border-box;
  padding-bottom: 4px;
}

.dots {
  position: absolute;
  height: 4px;
  bottom: 4px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.dot {
  width: 4px;
  height: 4px;
  margin: 0 2px;
  background-color: #f78e1e;
  border-radius: 50%;
}

.viewedDot {
  background-color: #1f8efa;
}
