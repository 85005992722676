.input {
  margin-top: 30px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

.closeButton {
  width: 105px;
  margin-right: 16px;
}

.saveButton {
  width: 215px;
}
