@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  height: 100%;
  align-items: flex-start;
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $main-font-color;
  word-break: break-word;
  padding: 10px 0 9px 8px;
}
