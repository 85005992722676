.wrapper {
  position: relative;
  display: flex;
  border: 1px solid #dbdde1;
  border-radius: 2px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 10px;
  margin-right: 15px;
  min-width: 143px;
  @media (max-width: 374px) {
    min-width: 116px;
  }
}

.placeholder {
  font-size: 14px;
}

.chevron {
  height: 10px;
  width: 14px;
  margin-left: 10px;
  background: url('./img/chevron.svg') no-repeat center;
  &.isOpen {
    transform: rotate(180deg);
  }
}

.dropdownPopup {
  position: absolute;
  background-color: #fff;
  padding: 12px 0;
  min-width: 200px;
  max-height: 276px;
  overflow-y: scroll;
  top: 30px;
  left: -20px;
  box-shadow: 0px 0px 2px rgba(47, 83, 151, 0.3),
    0px 0px 16px rgba(47, 83, 151, 0.1);
  border-radius: 4px;
  z-index: 11;
  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(143, 143, 153);
    border: 8px solid #fff;
    border-radius: 20px;
  }
  @media (max-width: 380px) {
    left: -5px;
    min-width: 150px;
  }
}

.popupItem {
  display: flex;
  cursor: pointer;
  padding: 12px 0 12px 24px;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 380px) {
    padding: 12px 20px;
  }
}

.selected {
  color: #8ec03e;
}

.checkboxLabel {
  cursor: pointer;
  width: 100%;
  &::before {
    content: url('./img/check_mark.svg');
    text-align: center;
    color: #fff;
    position: absolute;
    left: 20px;
    height: 18px;
    width: 18px;
    border: 1px solid #dbdde1;
    box-sizing: border-box;
    border-radius: 2px;
  }
}

input[type='checkbox'] {
  visibility: hidden;
  margin-right: 12px;
  &:checked + .checkboxLabel::before {
    background-color: #8ec03e;
  }
}
