.arrowIconLeft {
  position: absolute;
  left: 0px;
  margin-left: 12px;
}
.arrowIconRight {
  position: absolute;
  right: 0px;
  margin-right: 12px;
}
