@import 'styles/_variables.scss';

$breakpoint: $auth-breakpoint;

.wrapper {
  padding: 16px;
  margin: 0 auto;
  @media (min-width: $breakpoint) {
    padding: 0;
    width: auto;
    margin: 0;
  }
}

.container {
  position: relative;
  padding-top: 64px;
  @media (min-width: $breakpoint) {
    display: flex;
    min-height: 100vh;
    padding-top: 0;
    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
  }
}

.aside {
  color: #0c2152;
  @media (min-width: $breakpoint) {
    flex-basis: 480px;
    flex-grow: 1;
    box-shadow: 0 8px 16px rgba(148, 196, 61, 0.42);
    background-image: linear-gradient(to right, #8ec03e 0%, #a5ce39 100%);
    color: white;
    box-sizing: border-box;
    padding: 77px 38px;
  }
}

.asideContent {
  @media (min-width: $breakpoint) {
    width: 250px;
    margin: 0 auto;
  }
}

.title {
  font-size: 26px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.43px;
  position: relative;
  left: -1.5px;
  @media (min-width: $breakpoint) {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.53px;
  }
}

.note {
  max-width: 293px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;
  @media (min-width: $breakpoint) {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
  }
}

.infoNote {
  max-width: 293px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.23px;
  line-height: 22px;
  padding-bottom: 10px;
  @media (min-width: $breakpoint) {
    margin-top: 78px;
  }
}

.content {
  padding: 20px 0 0;
  @media (min-width: $breakpoint) {
    flex-grow: 3;
    flex-basis: 960px;
    box-sizing: border-box;
    padding: 64px 60px 20px 60px;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  @media (min-width: $breakpoint) {
    position: static;
  }
}

.logoImg {
  width: 149px;
  height: 40px;
  vertical-align: middle;
}

.context {
  display: block;
  max-width: 250px;
  margin-left: 10px;
  color: #6d7a97;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 10px;
  &.cutInfo {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.innerContent {
  max-width: 488px;
}
