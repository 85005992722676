.wrapper {
  position: relative;
  cursor: pointer;
  user-select: none;
}

.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
}
