.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 42px;
  border-radius: 21px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  background-image: linear-gradient(to right, #8ec03e 0%, #a5ce39 100%);
  box-sizing: border-box;
  padding: 0 20px;
  user-select: none;
  &.disabled {
    background-color: rgba(109, 122, 151, 0.4);
    box-shadow: none;
    background-image: none;
    cursor: default;
  }
  &.secondary {
    background-color: rgba(109, 122, 151, 0.4);
    box-shadow: none;
    background-image: none;
  }
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}
