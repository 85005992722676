@import 'styles/_variables.scss';

$breakpoint: $auth-breakpoint;

.input {
  margin-top: 30px;
}

.info {
  font-size: 12px;
  line-height: 16px;
  color: #8ec03e;
  margin-bottom: 25px;
}

.optionsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.forgetLink {
  background-image: linear-gradient(270deg, #a5ce39 0%, #8ec03e 100%);
  background-clip: text;
  color: #95c44a;
  font-size: 12px;
  font-weight: 700;
  flex-shrink: 0;
}

.buttons {
  text-align: center;
}

.errorBar {
  position: absolute;
  top: -10px;
}

.footerWrapper {
  position: absolute;
  bottom: 0;
  padding: 15px 0 10px;
  color: #6d7a97;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  .separator {
    margin: 0 10px;
  }
}
