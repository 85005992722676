$breakpoint: 780px;

.wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap-reverse;
  @media (min-width: $breakpoint) {
    flex-wrap: nowrap;
  }
}

.leftBlock {
  flex-grow: 1;
  flex-basis: 390px;
  box-sizing: border-box;
  margin-top: 25px;
  @media (min-width: $breakpoint) {
    margin-right: 30px;
  }
}

.asideWrapper {
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: $breakpoint) {
    flex-direction: column;
  }
}

.calendar,
.schedule {
  width: 360px;
  box-shadow: 0 8px 16px rgba(47, 83, 151, 0.1), 0 0 2px rgba(47, 83, 151, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  @media (max-width: $breakpoint) {
    width: 100%;
  }
}
.schedule {
  margin-bottom: 25px;
  @media (min-width: $breakpoint) {
    margin: 20px 0;
  }
}

.group {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

.groupTitle {
  color: #6d7a97;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  height: 16px;
  letter-spacing: 0.2px;
}

.noteCard {
  margin-top: 14px;
}
