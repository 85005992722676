.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.container {
  position: absolute;
}

.errorBanner {
  position: absolute;
  bottom: 90px;
  @media (max-width: 768px),
    (orientation: landscape) and (max-device-height: 640px) {
    position: fixed;
    left: 0;
  }
}
