$breakpoint: 780px;

.rightBlock {
  width: 100%;
  box-sizing: border-box;
  margin-top: 55px;
  @media (min-width: $breakpoint) {
    flex-grow: 1;
    max-width: 360px;
  }
}
