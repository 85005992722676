@import 'styles/_variables.scss';

$breakpoint: $auth-breakpoint;

.note {
  position: relative;
  color: #0c2152;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.23px;
  line-height: 22px;
  margin-bottom: 30px;
  @media (min-width: $breakpoint) {
    margin-bottom: 60px;
  }
}

.noteHidden {
  visibility: hidden;
}

.info {
  position: absolute;
  top: 50px;
  font-size: 12px;
  line-height: 16px;
  color: #8ec03e;
}

.buttons {
  text-align: center;
  margin-top: 50px;
  @media (min-width: $breakpoint) {
    margin-top: 0;
  }
}
