.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 420px;
  padding-bottom: 50px;
}

.currentCircle {
  width: 26px;
  height: 26px;
  box-shadow: 0 3px 5px rgba(109, 122, 151, 0.3);
  border: 1px solid rgba(226, 228, 234, 0.5);
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-image: linear-gradient(to right, #8ec03e 0%, #a5ce39 100%);
    border-radius: 50%;
  }
}

.todoCircle {
  width: 26px;
  height: 26px;
  min-width: 26px;
  border: 1px solid #e2e4ea;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: rgba(109, 122, 151, 0.6);
  font-size: 10px;
  font-weight: 600;
}

.completeCircle {
  box-sizing: border-box;
  background-image: linear-gradient(to right, #8ec03e 0%, #a5ce39 100%);
  border-radius: 50%;
  width: 26px;
  min-width: 26px;
  height: 26px;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 9px;
    height: 4px;
    border: solid 3px #fff;
    border-right: none;
    border-top: none;
    transform: translate(3px, 4px) rotate(-45deg);
  }
}

.line {
  border-bottom: 1px solid rgba(109, 122, 151, 0.2);
  display: inline-block;
  text-align: center;
  line-height: 1px;
  width: 100%;
  &:last-child {
    display: none;
  }
}

.activeLine {
  border-bottom-color: #8ec03e;
}

.tooltipWrapper {
  position: relative;
}

.tooltip {
  color: #0c2152;
  font-size: 12px;
  font-weight: 400;
  width: 160px;
  padding-top: 11px;
  padding-bottom: 11px;
  box-sizing: border-box;
  margin-top: 11px;
}
