.dialogButtons {
  display: flex;
  justify-content: flex-end;
  padding: 40px 10px 10px 0px;
}

.dialogContent {
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c5cad5;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #c5cad5;
  }
}

.dialogLine {
  margin-top: 10px;
  width: 100%;
  border-top: 1px solid #ced3dc;
  position: absolute;
  left: 0;
  right: 0;
}

.leaveButton {
  width: 105px;
  min-width: 90px;
  margin-right: 15px;
}

.agreeButton {
  width: 215px;
  min-width: 90px;
}
