$breakpoint: 780px;
$tablet: 1024px;

.wrapper {
  cursor: pointer;
  padding: 20px 30px;
  margin-bottom: 14px;
  box-shadow: 0 8px 16px rgba(47, 83, 151, 0.1), 0 0 2px rgba(47, 83, 151, 0.1);
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #ffffff;
  position: relative;
  border-left: 3px solid #e31717;
  &.paid {
    border-left: 3px solid #719d27;
  }
  &.processing {
    border-left: 3px solid rgb(250, 192, 46);
  }
  &:hover {
    box-shadow: 0 8px 16px rgba(47, 83, 151, 0.2),
      0 0 2px rgba(47, 83, 151, 0.1);
  }
  @media (max-width: 768px) {
    padding: 10px 30px;
  }
  @media (max-width: 420px) {
    padding: 10px 15px;
  }
}

.billDataWrapper {
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 1fr;
  align-items: center;
  gap: 20px;
  grid-template-areas: 'providerWrapper dueDateWrapper invoiceTotalWrapper payBtn';
  @media (max-width: 768px) {
    grid-template-columns: minMax(100px, 2fr) minmax(80px, 1fr) minmax(80px, 1fr);
    row-gap: 10px;
    column-gap: 5px;
    grid-template-areas:
      'providerWrapper providerWrapper providerWrapper'
      'dueDateWrapper invoiceTotalWrapper payBtn';
  }
  @media (max-width: 420px) {
    grid-template-columns: minMax(120px, 1fr) minmax(80px, 1fr) minmax(80px, 1fr);
  }
  @media (max-width: 360px) {
    grid-template-columns: minMax(80px, 1fr) minmax(80px, 1fr) minmax(80px, 1fr);
  }
}

.providerWrapper {
  grid-area: providerWrapper;
  display: flex;
  flex-direction: column;
  flex-basis: 35%;
  flex-shrink: 0;
  margin-top: 3px;

  .providerName {
    max-width: 260px;
  }

  .numberDateWrapper {
    display: flex;
    font-size: 12px;
    color: #6d7a97;
    margin-top: 10px;

    .billNumber,
    .billDate {
      flex-shrink: 0;
    }
    .billNumber {
      margin-right: 30px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.dueDateWrapper {
  grid-area: dueDateWrapper;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #6d7a97;
  @media (max-width: 420px) {
    & {
      font-size: 14px;
    }
    &::before {
      display: none;
    }
  }
}

.invoiceTotalWrapper {
  grid-area: invoiceTotalWrapper;
  width: 80px;
  .invoiceTotal {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #6d7a97;
    flex-shrink: 0;
    img {
      height: 16px;
    }
    @media (max-width: 420px) {
      font-size: 14px;
    }
    .alertRed {
      padding-left: 5px;
      @media (max-width: 360px) {
        height: 15px;
      }
    }
  }
}

.payBtn {
  grid-area: payBtn;
  justify-self: end;
  width: 100px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid rgba(144, 193, 62, 0.5);
  background-image: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #8ec03e;
}

.payBtnLoader {
  div {
    background: #8ec03e;
  }
}
