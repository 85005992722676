:root {
  --doc-height: 100%;
}

.wrapper {
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  max-height: 420px;
  width: 340px;
  box-shadow: 0 0 20px rgba(12, 33, 82, 0.2), 0 0 6px rgba(12, 33, 82, 0.3);
  background-color: #ffffff;
  border-radius: 13px;
  @media (max-width: 560px) {
    width: 90vw;
    max-height: var(--doc-height);
  }
}
