.tabList {
  display: flex;
  margin-bottom: 25px;
}

.tabContent {
  color: #0c2152;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.23px;
  line-height: 1.4;
  text-align: left;
  height: 300px;
  padding-right: 10px;
  padding-bottom: 10px;
  overflow-x: hidden;
  word-break: break-word;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
  }

  h1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  strong {
    font-weight: 700;
  }
  em {
    font-style: italic;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  li {
    margin-left: 15px;
  }
}

.tabListItem {
  cursor: pointer;
  margin-right: 28px;
  color: #8d919a;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 18px;
  &:last-child {
    margin-right: 0;
  }
}

.tabListActive {
  padding-bottom: 4px;
  border-bottom: 2px solid #91c24b;
  color: #0c2152;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 18px;
}
