@import 'styles/_variables.scss';

$breakpoint: 780px;

.wrapper {
  max-width: 300px;
  margin: 0 auto;
  padding: 30px 0;
  box-sizing: border-box;
  position: relative;
}

.header {
  color: #0c2152;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

.item {
  margin-top: 21px;
  &:first-child {
    margin-top: 0;
  }
  @media (max-width: $breakpoint) {
    padding: 0 15px;
  }
}

.title {
  color: #6d7a97;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.clinicianName {
  grid-area: clinicianName;
}
.time {
  grid-area: time;
  justify-self: end;
}
.action {
  grid-area: action;
  justify-self: end;
}

.body {
  margin-top: 5px;
  color: #0c2152;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'clinicianName time action';
  align-items: center;
  width: 300px;

  @media (max-width: 375px) {
    width: 270px;
  }
}

.btnWrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 19px;
}

.bottomBtnWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 20px;
  top: 6px;
}

.btn {
  color: #8ec03e;
  font-size: 14px;
  font-weight: 600;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}
