@import 'styles/_variables.scss';

$breakpoint: $auth-breakpoint;

.wrapper {
  @media (min-width: $breakpoint) {
    margin-top: 40px;
  }
}

.form {
  position: relative;
  margin-top: 60px;
}

.errorBar {
  position: absolute;
  top: -50px;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signAgreementsError {
  margin-top: 10px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

.nextBtn {
  width: 135px;
}

.prevBtn {
  width: 105px;
  margin-right: 16px;
}

.doneBtn {
  width: 135px;
}
