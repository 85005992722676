.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  min-width: 523px;
  box-sizing: border-box;
  padding: 20px;
  padding-right: 48px;
  box-shadow: 0 8px 16px rgba(47, 83, 151, 0.2),
    0 1px 2px rgba(47, 83, 151, 0.4);
  border-radius: 13px;
  background-color: #ffffff;
}

.fullScreenWrapper {
  position: fixed !important;
  width: 100vw !important;
  height: 100vh !important;
  top: 60px !important;
  left: 0 !important;
  overflow-y: auto !important;
  min-width: auto !important;
}

.closeBtn {
  height: 17px;
  width: 17px;
  position: absolute;
  top: 20px;
  right: 20px;
  &:after,
  &:before {
    position: absolute;
    content: ' ';
    height: 20px;
    width: 2px;
    top: -1px;
    right: 8px;
    background-color: #0c2152;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.title {
  color: #0c2152;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.data {
  margin-top: 20px;
}

.block {
  margin-top: 24px;
  &:first-child {
    margin-top: 0;
  }
}

.blockTitle {
  color: #6d7a97;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 5px;
}

.blockText {
  color: #0c2152;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-line;
  &.link {
    display: block;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
