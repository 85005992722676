@import 'styles/_variables.scss';

$breakpoint: $profile-breakpoint;

.wrapper {
  max-width: 524px;
}

.input {
  margin-top: 30px;
}

.toggleEditMode {
  margin-top: 22px;
}

.buttonWrapper {
  display: flex;
}

.cancelBtn {
  width: 100px;
  margin-right: 20px;
}

.cancelBtn,
.saveBtn {
  margin-top: 30px;
}

.passwordBlock,
.paymentBlock {
  margin: 40px 0;
}

.loader {
  margin-top: 0px;
  width: 150px;
  & > :first-child {
    height: 58px;
  }
  & div {
    top: 0px;
  }
}