.shade {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100vw;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  z-index: 0;
}

.dialog {
  display: inline-block;
  width: 100%;
  max-width: 580px;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  box-sizing: border-box;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(47, 83, 151, 0.2),
    0 1px 2px rgba(47, 83, 151, 0.4);
  border-radius: 13px;
  border: 1px solid #ced3dc;
  text-align: center;
  overflow: auto;
  z-index: 10;
  @media (max-width: 640px) {
    max-width: unset !important;
  }
}

.header {
  position: relative;
}

.title {
  color: #0c2152;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding: 0 30px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.closeBtn {
  height: 17px;
  width: 17px;
  position: absolute;
  top: 2px;
  right: 0;
  &:after,
  &:before {
    position: absolute;
    content: ' ';
    height: 20px;
    width: 2px;
    top: -1px;
    right: 8px;
    background-color: #0c2152;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
