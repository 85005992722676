@import 'styles/_variables.scss';

$breakpoint: $auth-breakpoint;

.input {
  margin-top: 30px;
}

.note {
  color: #0c2152;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.23px;
  line-height: 22px;
  margin-bottom: 20px;
}

.hint {
  color: #0c2152;
  font-size: 12px;
  font-weight: 400;
  margin-top: 30px;
  position: relative;
}

.hintLink {
  color: #8dc232;
  font-weight: 700;
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  text-align: center;
}

.tooltipBlock {
  color: #8dc232;
  padding: 13px 9px;
  transform: translateY(-50%);
  max-width: unset;
  margin-top: 5px;
  font-weight: 600;
  top: 0;
  right: unset;
  &::after {
    content: '';
    height: 7px;
    width: 6px;
    top: 50%;
    right: 99%;
    left: unset;
    border-top: 1px solid #fff;
    border-bottom: 1px solid rgba(226, 228, 234, 0.7);
    border-right: 1px solid #fff;
    border-left: 1px solid rgba(226, 228, 234, 0.7);
    transform: rotate(45deg) translateY(-50%) translateX(-50%);
    background: #fff;
  }
}

.errorBar {
  position: absolute;
  top: -10px;
}
