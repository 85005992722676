.group {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

.groupTitle {
  margin: 10px 0;
  color: #6d7a97;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  height: 16px;
  letter-spacing: 0.2px;
}

.buttons {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageBtn {
  width: 138px;
  height: 36px;
  border-radius: 20px;
  border: 1px solid rgba(144, 193, 62, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8ec03e;
  font-size: 12px;
  font-weight: 600;
}
