@import 'styles/_variables.scss';

.wrapper {
  text-align: center;
}

.description {
  color: $main-font-color;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.actions {
  margin-top: 5px;
  display: flex;
  flex-direction: column;

  &.horizontalActions {
    width: 100%;
    flex-direction: row;
    flex-direction: row-reverse;
    //flex-wrap: wrap;
  }
}

.btn {
  display: block;
  width: 45%;
  border-radius: 20px;
  padding: 7px 20px;
  text-align: center;
  margin: 26px 13px 0 13px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.46px;
  line-height: 26px;
}

.primaryBtn {
  background-color: $green-color;
  color: #fff;
}

.secondaryBtn {
  background-color: #c5cad5;
  color: #fff;
}

.dangerBtn {
  color: $error-color;
}
