.wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0 30px;
}

.label {
  font-size: 12px;
  margin-right: 9px;
}
