$breakpoint: 780px;

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  height: 80vh;
  @media (min-width: $breakpoint) {
    flex-direction: row;
  }
}

.schedule {
  width: 100%;
  margin-top: 50px;
  box-sizing: border-box;
  @media (min-width: $breakpoint) {
    box-shadow: 0 8px 16px rgba(47, 83, 151, 0.1),
      0 0 2px rgba(47, 83, 151, 0.1);
    border-radius: 4px;
    background-color: #ffffff;
    padding: 0 15px;
  }
}
