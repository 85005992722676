@import 'styles/_variables.scss';

$breakpoint: $profile-breakpoint;

.wrapper {
  display: inline-flex;
  align-items: center;
  margin-right: 17px;
}

.container {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 20px;
  img {
    height: 23px;
  }

  @media (max-width: 420px) {
    margin-left: 10px;
  }
}

.popup {
  width: 210px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
