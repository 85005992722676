.wrapper {
  padding: 12px 24px;
  box-sizing: border-box;
  box-shadow: 0 8px 16px rgba(47, 83, 151, 0.1),
    0 1px 2px rgba(47, 83, 151, 0.3);
  border-radius: 4px;
  background: #fff;
  user-select: none;
}

.item {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  color: #0c2152;
  padding: 12px 0;
  cursor: pointer;
  box-sizing: border-box;
  &.separatedItem {
    padding-top: 24px;
    margin-top: 12px;
    border-top: 1px solid rgba(206, 211, 220, 0.6);
  }
  &::before {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
  &.profile::before {
    content: url('./img/user.svg');
  }
  &.bills::before {
    content: url('./img/dollar_sign.svg');
  }
  &.logout::before {
    content: url('./img/logout.svg');
  }
}

.title {
  max-width: 260px;
  margin-right: 20px;
  &.cutInfo {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.checkmark {
  position: absolute;
  right: 0;
  background-image: url('./img/check@2x.png');
  background-size: cover;
  width: 19px;
  height: 14px;
}
