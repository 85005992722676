@import 'styles/_variables.scss';

$breakpoint: $auth-breakpoint;

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  @media (min-width: $breakpoint) {
    margin-top: 40px;
    min-height: 322px;
    margin-bottom: 46px;
  }
}
