.provider {
  display: flex;
  align-items: center;
  position: relative;
}

.providerName {
  cursor: pointer;
  color: #0c2152;
  font-size: 16px;
  max-width: 250px;
  font-weight: 400;
  line-height: 24px;
  padding-right: 6px;
  &.cutInfo {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  @media (max-width: 570px) {
    max-width: 200px;
  }
  @media (max-width: 490px) {
    max-width: 150px;
  }
  @media (max-width: 420px) {
    max-width: 110px;
  }
}

.icon {
  background-image: url('./img/info@2x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-bottom: 1px;
}
