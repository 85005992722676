.wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.popup {
  width: 210px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}
