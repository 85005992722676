.wrapper {
  position: relative;
  display: block;
}

.disabled {
  opacity: 0.5;
}

.input {
  border: 1px solid #dbdde1;
  border-radius: 2px;
  background-color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  height: 48px;
  padding: 0 11px;
  width: 100%;
  box-sizing: border-box;
  color: #6d7a97;
  font-size: 12px;
  box-sizing: border-box;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-style: italic;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-style: italic;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    font-style: italic;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    font-style: italic;
  }
}

.errorInput {
  border: 1px solid #f44236;
  color: #f44236;
}

.errorMsg {
  position: absolute;
  left: 0;
  bottom: -16px;
  color: #f44236;
  font-size: 12px;
  line-height: 18px;
}

.passwordInput {
  padding-right: 25px + 12px + 11px;
}

.label {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: #0c2152;
  display: block;
  text-align: left;
  margin-bottom: 8px;
}

.iconWrapper {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 25px;
  text-align: center;
}

.passwordIcon {
  color: #3a4a72;
  font-size: 20px;
}
