$breakpoint: 900px;

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 456px;
  height: 100%;
  margin: 0 auto;
}

.logoImg {
  max-width: 186px;
  max-height: 50px;
}

.title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0.53px;
  color: #0c2152;
  margin-top: 37px;
  margin-bottom: 22px;
  @media (min-width: $breakpoint) {
    font-size: 32px;
    line-height: 36px;
    font-weight: 600;
  }
}

.content {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.33px;
  color: #0c2152;
  padding: 0 15px;
  @media (min-width: $breakpoint) {
    font-size: 20px;
    line-height: 28px;
  }
}

.btn {
  margin-top: 30px;
  @media (min-width: $breakpoint) {
    margin-top: 72px;
  }
}
