.mainWrapper {
  width: 300px;
  margin: 0 auto;
  padding: 30px 0;
  @media (max-width: 350px) {
    width: 260px;
    padding: 30px 15px;
  }
}

.container {
  width: 100%;
  color: #0c2152;
  display: inline-block;
  font-size: 1rem;
}

.wrapper {
  position: relative;
  flex-direction: row;
  user-select: none;
  outline: none;
}

.months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.month {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  user-select: none;
}

.navBar {
  display: block;
}

.outside {
  visibility: hidden;
  cursor: default;
}

.disabled {
  cursor: default !important;
}

.selected {
  color: white;
  font-weight: 700;
  box-shadow: 0 8px 16px rgba(148, 196, 61, 0.42);
  background-image: linear-gradient(to right, #8ec03e 0%, #a5ce39 100%);
  & li {
    background-color: white !important;
  }
}

.today {
  cursor: default;
}

.navButtonPrev,
.navButtonNext {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: -1px;
  &:hover {
    opacity: 0.8;
  }
  outline: none;
}

.navButtonPrev {
  right: 40px;
  background-image: url('./img/left-arrow@2x.png');
  width: 11px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navButtonNext {
  background-image: url('./img/right-arrow@2x.png');
  width: 11px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navButtonInteractionDisabled {
  opacity: 0.4 !important;
}

.caption {
  margin-bottom: 0.5em;
  text-align: left;
}

.weekdays {
  margin-top: 21px;
}

.weekdaysRow {
  display: flex;
  justify-content: space-between;
}

.weekday {
  width: 33px;
  color: rgba(109, 122, 151, 0.6);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2px;
  display: inline-block;
  text-align: center;
}

.body {
  display: block;
}

.week {
  display: flex;
  justify-content: space-between;
}

.day {
  width: 33px;
  height: 33px;
  display: inline-block;
  margin-top: 8px;
  outline: none;
  border-radius: 50%;
  cursor: pointer;
}

.weekNumber {
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 12px;
  cursor: pointer;
}

.legends {
  margin-top: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #6d7a97;
  font-size: 12px;
  font-weight: 400;
  & > li {
    padding-left: 14px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #f78e1e;
    }
    &.viewedLegend::before {
      background-color: #1f8efa;
    }
  }
}
