.note,
.error {
  margin-top: -26px;
  color: #0c2152;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.23px;
  line-height: 22px;
  margin-bottom: 30px;
}

.error {
  color: #f44236;
}

.input {
  margin-top: 30px;
  &:first-child {
    margin-top: 0;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

.closeButton {
  width: 105px;
  margin-right: 16px;
}

.saveButton {
  width: 215px;
}
