$breakpoint: 780px;

.noDataWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.dataWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
}

.img {
  width: 92px;
  height: 92px;
}

.title {
  text-align: center;
  margin-top: 22px;
  color: #0c2152;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}

.note {
  margin-top: 18px;
  font-size: 16px;
  color: #0c2152;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.btn {
  color: #8ec03e;
  font-weight: 700;
}
