.wrapper {
  display: block;
}

.switch {
  opacity: 1 !important;
}

.label {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: #0c2152;
  display: block;
  text-align: left;
  margin-bottom: 5px;
}
