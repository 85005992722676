.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(47, 83, 151, 0.1), 0 0 2px rgba(47, 83, 151, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
}

.note {
  margin-top: 13px;
  color: #6d7a97;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.action {
  margin-top: 15px;
  display: inline-block;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
}
