.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap-reverse;
}

.left {
  margin-top: 30px;
  flex: 1;
  padding-right: 90px;
  @media (max-width: 500px) {
    padding-right: 0;
    min-width: 100%;
  }
}

.right {
  margin-top: 30px;
  flex: 1;
  min-width: 400px;
  @media (max-width: 500px) {
    min-width: 100%;
  }
}
