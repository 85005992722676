.wrapper {
  position: absolute;
  border-radius: 2px;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  padding: 9px 12px;
  border: 1px solid rgba(226, 228, 234, 0.7);
  box-shadow: 0 3px 5px rgba(109, 122, 151, 0.1);
  margin-left: 12px;
  background: #fff;
  top: 100%;
  margin-top: 8px;
  z-index: 6;
  text-align: center;
  &::after {
    content: '';
    position: absolute;
    height: 5px;
    width: 4px;
    right: 100%;
    left: calc(50% - 4px);
    top: 0;
    border-top: 1px solid rgba(226, 228, 234, 0.7);
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    border-left: 1px solid rgba(226, 228, 234, 0.7);
    transform: rotate(45deg) translateY(-50%) translateX(-50%);
    background: #fff;
  }
}

.position-center {
  transform: translateX(50%);
  right: 50%;
  &::after {
    left: calc(50% - 4px);
  }
}

.position-left {
  left: 0;
  margin-left: 0;
  &::after {
    left: 8px;
  }
}

.position-right {
  right: 0;
  margin-left: 0;
  &::after {
    left: auto;
    right: 9px;
  }
}
