@import 'styles/_variables.scss';

.Toastify__toast-container {
  width: 322px;
}

.Toastify__toast {
  background: #ffffff;
  box-shadow: 0px -3px 20px rgba(184, 192, 205, 0.72);
  border-radius: 6px;
  min-height: 54px;
  padding-top: 10px;
  padding-left: 14px;

  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}

.Toastify__toast-body {
  margin: 0;
  font-size: 0;
}

.Toastify__progress-bar {
  top: 0;
  bottom: auto;
  height: 3px;
}

.Toastify__progress-bar--error {
  background: $error-color;
}

.Toastify__progress-bar--success {
  background: $green-color;
}
