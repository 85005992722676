@import 'styles/_variables.scss';

$breakpoint: $auth-breakpoint;

.input {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}

.headerWrapper {
  position: relative;
  margin-bottom: 60px;
}

.headerInfo {
  color: #0c2152;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.23px;
  line-height: 22px;
  span {
    font-weight: bold;
  }
}

.errorBar {
  position: absolute;
  margin-top: 5px;
}

.link {
  color: #97c53d;
  font-weight: 700;
  padding-left: 5px;
}

.options {
  margin-top: 30px;
  letter-spacing: 0.23px;
  position: relative;
  @media (min-width: $breakpoint) {
    margin-top: 50px;
  }
}

.termsAndConditionsButton {
  color: #97c53d;
  margin-left: 5px;
}

.errorMsg {
  position: absolute;
  top: 30px;
  left: 0;
  bottom: -16px;
  color: #f44236;
  font-size: 12px;
  line-height: 18px;
}
