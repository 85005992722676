.wrapper {
  width: 100%;
}

.backBtn {
  display: block;
  color: #95c44a;
  font-size: 12px;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 30px;
  position: relative;
  padding-left: 24px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('./img/back-arrow@2x.png');
    width: 16px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
