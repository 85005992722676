@import 'styles/_variables.scss';

.paymentWrapper {
  display: flex;
  column-gap: 20px;
  align-items: center;

  .cardImg {
    height: 20px;
    width: auto;
  }
}

.remove {
  color: $green-color;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 20px;
}

.status {
  padding-bottom: 15px;
}