.wrapper {
  display: flex;
}

.patient {
  color: #05102a;
  max-width: 250px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  &.cutInfo {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  @media (max-width: 570px) {
    max-width: 200px;
  }
  @media (max-width: 490px) {
    max-width: 150px;
  }
  @media (max-width: 420px) {
    max-width: 110px;
  }
}

.separator {
  color: #0c2152;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 5px;
}
