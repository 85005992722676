.title {
  color: #0c2152;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 24px;
}

.note {
  margin-top: 20px;
  color: #0c2152;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.23px;
  line-height: 22px;
}
