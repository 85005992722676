.errorBar {
  text-align: left;
  padding-top: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 15px 0px;
}

.cancelButton {
  max-width: 105px;
  min-width: 90px;
  width: 100%;
  margin-right: 15px;
}

.payNowButton {
  max-width: 145px;
  min-width: 90px;
  width: 100%;
}