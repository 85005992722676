.buttonsWrapper {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 30px;
}

.groupedButtons {
  display: flex;
}

.btnSeparator {
  margin: 0 15px;
  color: #6d7a97;
  opacity: 0.3;
}

.actionBtn {
  cursor: pointer;
  display: block;
  color: #95c44a;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  &.back {
    padding-left: 24px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('./img/back-arrow@2x.png');
      width: 16px;
      height: 12px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
