.wrapper {
  position: relative;
  width: 30px;
  height: 30px;
}

.back {
  position: absolute;
  left: 0;
  top: 0;
}

.iconWrapper {
  position: absolute;
  top: 16px;
  left: 18px;
  width: 17px;
  height: 17px;
  background-color: white;
  border-radius: 50%;
}

.icon {
  position: absolute;
  top: 2px;
  left: 2px;
  background-image: url('./img/clock@2x.png');
  width: 14px;
  height: 13px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
