.wrapper {
  z-index: 0;
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #333;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  width: 100%;
  .input {
    z-index: -1;
    position: absolute;
    left: 0px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
    &:checked + .label::before,
    &:indeterminate + .label::before {
      border-color: #97c53d;
      background-color: #97c53d;
      box-shadow: 0 8px 16px rgba(148, 196, 61, 0.42);
    }
    &:checked + .label::after,
    &:indeterminate + .label::after {
      border-color: #fff;
    }
    &:indeterminate + .label::after {
      border-left: none;
      transform: translate(4px, 3px);
    }
  }
  .label {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    &:before {
      content: '';
      display: block;
      box-sizing: border-box;
      /* Safari */
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #dbdde1;
      width: 24px;
      height: 24px;
      float: left;
      transition: border-color 0.2s, background-color 0.2s;
      margin-right: 10px;
    }
    /* Checkmark */
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 2px;
      width: 11px;
      height: 5px;
      border: solid 3px transparent;
      border-right: none;
      border-top: none;
      transform: translate(3px, 4px) rotate(-45deg);
    }
  }
}
